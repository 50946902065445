'use client';

import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { FC, PropsWithChildren } from 'react';

import { useConsentState } from '@lichtblick/consent/hooks/useConsentState';
import { useUsercentricsControllerId } from '@lichtblick/consent/hooks/useUsercentricsControllerId';
import { ConsentState, OPTIMIZELY_NAME } from '@lichtblick/consent/UsercentricsSingleton/UsercentricsSingleton.types';

export const OptimizelyWrapper: FC<PropsWithChildren> = ({ children }) => {
  const ucControllerId = useUsercentricsControllerId() || null;
  const [optimizelyConsentState] = useConsentState(OPTIMIZELY_NAME);

  const optimizelyInstance = createInstance({
    sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
    logLevel: process.env.NODE_ENV !== 'development' ? 'error' : 'info',
    odpOptions: { disabled: true },
  });

  return (
    <OptimizelyProvider
      optimizely={optimizelyInstance}
      user={{ id: optimizelyConsentState === ConsentState.Given ? ucControllerId : null }}
    >
      {children}
    </OptimizelyProvider>
  );
};

import { SearchIconXs } from '@lichtblick/icons/svg/search/search-xs';
import styled from '@lichtblick/styled';
import { MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { StyledBoxCTA } from '@lichtblick/ui-components';
import { Wrapper as TextfieldWrapper } from '@lichtblick/ui-components/molecules/Textfield/Textfield.styles';

export const Root = styled.div`
  /* stylelint-disable-next-line meowtec/no-px */
  max-width: 800px;
  margin: 0 auto;
`;

export const FunnelForm = styled.form`
  width: 100%;
  padding-block: ${Spacing.Xs};
  padding-inline: ${Spacing.S};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S};
`;

export const Controls = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing.Xxs};
  ${TextfieldWrapper} {
    flex-grow: 1;
  }
  ${StyledBoxCTA} {
    flex-shrink: 0;
    flex-grow: 1;
  }
  ${MediaSmallAndAbove} {
    gap: ${Spacing.S};
  }
`;

export const StyledSearchIcon = styled(SearchIconXs)`
  margin-right: ${Spacing.Xs};
`;

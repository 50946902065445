import { FC, PropsWithChildren } from 'react';

import { AButton, AImage, MTextIntro, StyledMList } from '@lichtblick/contentful';
import { Items, Item } from '@lichtblick/contentful/components/OLogos/OLogos.styles';
import { CheckIconS } from '@lichtblick/icons/svg/check/check-s';
import { CheckIconXs } from '@lichtblick/icons/svg/check/check-xs';
import { Container } from '@lichtblick/ui-components';

import { SoftFunnel } from './components/SoftFunnel';
import {
  Root,
  StageContainer,
  ContentContainer,
  TextContainer,
  StyledListItem as StyledListItemImpl,
  H1Container,
  ImageContainer,
  SellingPointList,
} from './SolarStageExperiment.styles';

import { componentMapper, richTextMapper } from '../../../../helpers';
import { SolarStageType } from '../../../../types';
import { getColor } from '../../../../utils';
import { SellingPointsSection, SellingPointItem as SellingPointItemImpl } from '../Baseline/SolarStage.styles';

const TextIntro: FC<Pick<SolarStageType, 'textIntro'>> = ({ textIntro }) => {
  if (!textIntro?.length) {
    return null;
  }

  const { button: _, ...rest } = textIntro[0];

  return (
    <MTextIntro
      {...rest}
      headlineProps={{
        size: 'L',
        renderAs: 'h1',
      }}
      textProps={{
        renderAs: 'div',
        align: 'left',
      }}
    />
  );
};

const StyledListItem: FC<PropsWithChildren> = ({ children }) => (
  <StyledListItemImpl>
    <CheckIconXs />
    {children}
  </StyledListItemImpl>
);

const SellingPointItem: FC<PropsWithChildren> = ({ children }) => (
  <SellingPointItemImpl>
    <CheckIconS />
    {children}
  </SellingPointItemImpl>
);

export type SolarStageExperimentProps = SolarStageType & { hasSoftFunnelEntry?: boolean };

export const SolarStageExperiment: FC<SolarStageExperimentProps> = ({
  followingSectionTheme,
  hasSoftFunnelEntry,
  logos,
  media,
  sellingPoints,
  textIntro,
}) => {
  const mediaItem = media?.[0]?.component === 'a-image' ? { ...media[0], imageDesktop: undefined } : undefined;
  const button = textIntro?.[0]?.button?.[0];

  return (
    <Root data-testid={`solar-stage-experiment${hasSoftFunnelEntry ? '-soft-funnel' : ''}`} style={{ display: 'none' }}>
      <StageContainer>
        <ContentContainer>
          <TextContainer $hasSoftFunnelEntry={hasSoftFunnelEntry}>
            {logos?.length && (
              <Items $isVerticallyCentered={false}>
                {logos.map((item) => (
                  <Item $length={logos.length} key={item._uid}>
                    {componentMapper(item)}
                  </Item>
                ))}
              </Items>
            )}
            <H1Container>
              {textIntro?.[0] && <TextIntro textIntro={textIntro} />}
              {hasSoftFunnelEntry &&
                sellingPoints &&
                richTextMapper(sellingPoints, {
                  ul: StyledMList,
                  li: StyledListItem,
                })}
              {Boolean(button) && !hasSoftFunnelEntry && <AButton {...button} isWide={false} />}
            </H1Container>
          </TextContainer>
        </ContentContainer>
        {mediaItem && (
          <ImageContainer data-image-container>
            {mediaItem ? <AImage {...mediaItem} isBackground loading="eager" maxWidth={1920} /> : null}
          </ImageContainer>
        )}
      </StageContainer>
      <SellingPointsSection $backgroundColor={getColor(followingSectionTheme)}>
        <Container as="div">
          {hasSoftFunnelEntry ? (
            <SoftFunnel />
          ) : (
            sellingPoints &&
            richTextMapper(sellingPoints, {
              ul: SellingPointList,
              li: SellingPointItem,
            })
          )}
        </Container>
      </SellingPointsSection>
    </Root>
  );
};

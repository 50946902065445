import { FormEventHandler, useCallback, useState } from 'react';

import { BoxCTA, Textfield } from '@lichtblick/ui-components';
import { Text } from '@lichtblick/ui-components/atoms/Text';

import { Root, FunnelForm, Controls, StyledSearchIcon } from './SoftFunnel.styles';

import { SellingPointList } from '../SolarStageExperiment.styles';

const DEFAULT_REQUIRED_ERROR_MESSAGE = 'Hier fehlt noch eine Angabe';
const DEFAULT_INVALID_ERROR_MESSAGE = 'Bitte überprüfe deine Angabe';

export const SoftFunnel = () => {
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string | undefined>(undefined);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault();

    const postalCode = new FormData(event.currentTarget).get('postalCode')?.toString();

    if (!postalCode) {
      setPostalCodeErrorMessage(DEFAULT_REQUIRED_ERROR_MESSAGE);

      return;
    }

    if (!/^\d{5}$/.test(postalCode)) {
      setPostalCodeErrorMessage(DEFAULT_INVALID_ERROR_MESSAGE);

      return;
    }

    location.assign(`/zuhause-checkout?buildingPostalCode=${postalCode}`);
  }, []);

  return (
    <Root>
      <SellingPointList as="div">
        <FunnelForm onSubmit={onSubmit}>
          <Text isBold>Wo soll Deine SolarAnlage installiert werden?</Text>
          <Controls>
            <Textfield
              leftSlot={<StyledSearchIcon />}
              name="postalCode"
              onChange={(event) =>
                setPostalCodeErrorMessage(event.target.value ? undefined : DEFAULT_REQUIRED_ERROR_MESSAGE)
              }
              onInvalid={(e) => {
                e.preventDefault();
                setPostalCodeErrorMessage(
                  e.currentTarget.value ? DEFAULT_INVALID_ERROR_MESSAGE : DEFAULT_REQUIRED_ERROR_MESSAGE,
                );
              }}
              placeholder="Postleitzahl eingeben"
              required
              statusLabel={postalCodeErrorMessage}
              statusType={postalCodeErrorMessage ? 'error' : 'default'}
              type="text"
            />
            <BoxCTA as="button" isDisabled={false}>
              SolarAnlage berechnen
            </BoxCTA>
          </Controls>
        </FunnelForm>
      </SellingPointList>
    </Root>
  );
};

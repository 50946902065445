import { FC } from 'react';

import { Text, TextCTA } from '@lichtblick/ui-components';

import { ColumnItemContainer, StyledHeadline } from './MColumnItem.styles';

import { buildUrl } from '../../helpers';
import { componentMapper } from '../../helpers/componentMapper';
import { MColumnItemType } from '../../types/storyblok';

export type ColumnItemProps = MColumnItemType & {
  columnAlignment?: string | null;
  columnWidth?: string | null;
};

export const MColumnItem: FC<ColumnItemProps> = ({
  columnAlignment,
  columnWidth,
  headline,
  link,
  linkLabel,
  media,
  text,
}) => {
  const href = link && buildUrl(link);

  return (
    <ColumnItemContainer $alignment={columnAlignment ?? ''} $width={columnWidth ?? ''} data-testid="column-item">
      {media?.[0] && componentMapper(media[0])}
      {headline && (
        <StyledHeadline renderAs="h3" size="S">
          {headline}
        </StyledHeadline>
      )}
      {text && <Text>{text}</Text>}
      {Boolean(href) && Boolean(linkLabel) && (
        <TextCTA as="a" href={href}>
          {linkLabel}
        </TextCTA>
      )}
    </ColumnItemContainer>
  );
};

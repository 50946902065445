import { useTrackEvent } from '@optimizely/react-sdk';

import { useConsentState } from '@lichtblick/consent/hooks/useConsentState';
import { ConsentState, OPTIMIZELY_NAME } from '@lichtblick/consent/UsercentricsSingleton/UsercentricsSingleton.types';

export const useOptimizelyTrack = () => {
  const [track] = useTrackEvent();

  const [optimizelyConsentState] = useConsentState(OPTIMIZELY_NAME);

  if (optimizelyConsentState !== ConsentState.Given) {
    (_event: string) => {
      //no-op
    };
  }

  return (event: string) => track(event);
};

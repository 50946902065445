import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { BelowMedium } from '@lichtblick/theme';

import { Image, Video } from './AVideo.styles';

import { AVideoType, VideoSourceType } from '../../types/storyblok';
import { getImageDimensions } from '../AImage/Picture.helpers';

const isValid = (source: VideoSourceType | undefined) =>
  Boolean(source?.poster?.filename || source?.webM?.filename || source?.mp4?.filename);

export type AVideoProps = AVideoType & {
  className?: string;
  shouldPlayInBackground?: boolean;
};

export const AVideo: FC<AVideoProps> = ({
  altText,
  autoplay: isAutoplay = false,
  className,
  isMobileImageOnly,
  shouldPlayInBackground = false,
  videoSourceDesktop,
  videoSourceMobile,
}) => {
  const [isMobile, setIsMobile] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const isAutoPaused = useRef(false);
  const resumeAt = useRef(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  const source = useMemo<VideoSourceType | undefined>(() => {
    if (!isMobile) {
      return [videoSourceDesktop?.[0], videoSourceMobile[0]].filter(isValid)[0];
    } else if (!isMobileImageOnly) {
      return videoSourceMobile[0];
    }
  }, [isMobile, isMobileImageOnly, videoSourceDesktop, videoSourceMobile]);

  useEffect(() => {
    const mediaQueryObserver = window.matchMedia(BelowMedium);
    const intersectionObserver = new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting));
    const handleMediaQueryChange = () => setIsMobile(mediaQueryObserver.matches);
    const video = videoRef.current;

    mediaQueryObserver.addEventListener('change', handleMediaQueryChange);
    handleMediaQueryChange();

    if (video) {
      intersectionObserver.observe(video);
    }

    return () => {
      mediaQueryObserver.removeEventListener('change', handleMediaQueryChange);
      intersectionObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const isPaused = video.paused; // must be checked before `load()`

      resumeAt.current = video.currentTime;
      video.load();
      video.currentTime = resumeAt.current;

      if (!isPaused) {
        video.play();
      }
    }
  }, [source]);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      isAutoPaused.current ||= shouldPlayInBackground || isAutoplay;

      if (!isVisible) {
        isAutoPaused.current ||= !video.paused;
        video.pause();
      } else if (isAutoPaused.current) {
        isAutoPaused.current = false;
        video.currentTime = resumeAt.current;
        video.play();
      }
    }
  }, [isVisible, shouldPlayInBackground, isAutoplay]);

  const saveCurrentTime = () => (resumeAt.current = videoRef.current?.currentTime ?? 0);

  const posterDimensions = source?.poster.filename ? getImageDimensions(source?.poster.filename) ?? [] : [];

  return (
    <>
      {isMobileImageOnly && (
        <Image
          $isBackground={shouldPlayInBackground}
          altText={altText ?? ''}
          className={className}
          imageMobile={videoSourceMobile[0].poster}
        />
      )}
      <Video
        $isBackground={shouldPlayInBackground}
        $isVisible={isValid(source)}
        autoPlay={shouldPlayInBackground || isAutoplay}
        className={className}
        controls={!shouldPlayInBackground}
        height={posterDimensions[1]}
        loop={shouldPlayInBackground || isAutoplay}
        muted={shouldPlayInBackground || isAutoplay}
        onPause={saveCurrentTime}
        onSeeked={saveCurrentTime}
        playsInline
        poster={source?.poster.filename && `${source?.poster.filename}/m/`}
        preload="none"
        ref={videoRef}
        width={posterDimensions[0]}
      >
        {([source?.webM?.filename, source?.mp4.filename].filter(Boolean) as string[]).map((src) => (
          <source key={src} src={src} type={`video/${src.split('.').pop()?.toLowerCase()}`} />
        ))}
        {altText || 'Video'}
      </Video>
    </>
  );
};
